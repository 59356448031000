<section class="top-country-imports country-imports" *ngIf="!isLoading">
  <app-header [model]="model"></app-header>
  <ng-container *ngIf="model.totalImportValue > 0; else elseTemplate">
    <div class="imports">
      <div class="imports-list-header">
        <table class="table table-imports">
          <thead>
              <tr>
                <th style="padding-left: 20px;">ÜLKE</th>
                <th class="imports">İTHALAT</th>
              </tr>
            </thead>
          </table>
      </div>
      <div class="imports-list-body">
          <table class="table table-imports" *ngFor="let item of model.items" (click)="onRowClick(item)">
              <tbody>
                <tr>
                  <td class="desc country">
                    <div>{{ item.country.nameTr }}</div>
                  </td>
                  <td class="imports">
                    <app-currency-formatter [value]="item.trade"></app-currency-formatter>
                  </td>
                  <td class="arrow">
                    <img src="../../assets/img/arrow-right.svg" />
                  </td>
                </tr>
              </tbody>
            </table>
      </div>

      <app-footer></app-footer>

    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="no-result">
      <div class="pb-5">Bu ürün için herhangi bildirilmiş bir ithalat kaydı bulunmamaktadır. Lütfen başka bir ürün seçiniz.</div>
      <button type="button" class="btn btn-primary" [routerLink]="['/hs']" routerLinkActive="router-link-active" >BAŞKA BİR ÜRÜN SEÇ</button>
    </div>
  </ng-template>
</section>
