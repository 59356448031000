import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GtipComponent } from './gtip/gtip.component';
import { ImportDetailsComponent } from './import-details/import-details.component';
import { CountrySearchComponent } from './country-search/country-search.component';
import { HsSearchComponent } from './hs-search/hs-search.component';
import { ImportsByCountriesComponent } from './imports-by-countries/imports-by-countries.component';
import { ContactsComponent } from './contacts/contacts.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'country', component: CountrySearchComponent},
  { path: 'hs', component: HsSearchComponent},
  { path: 'contacts/:country', component: ContactsComponent },
  { path: 'imports/:country', component: GtipComponent },
  { path: 'imports/:country/:hsCode', component: GtipComponent },
  { path: 'imports-by-countries/:hsCode', component: ImportsByCountriesComponent },
  { path: 'import-details/:country/:hsCode', component: ImportDetailsComponent },
  {path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
