<section class="top-country-imports country-imports" *ngIf="!isLoading">
  <app-header [model]="model"></app-header>
  <ng-container *ngIf="model.totalImportValue > 0; else elseTemplate">
    <div class="imports">
      <div class="imports-list-header">
        <table class="table table-imports">
          <thead>
              <tr>
                <th>
                  <span *ngIf="!model.hsCode">ÜRÜN GRUBU</span>
                  <span *ngIf="model.hsCode">GTİP</span>
                </th>
                <th class="imports">İTHALAT</th>
              </tr>
            </thead>
          </table>
      </div>
      <div class="imports-list-body">
          <table class="table table-imports" *ngFor="let item of model.items" (click)="onRowClick(item)">
              <tbody>
                <tr>
                  <td class="gtip" *ngIf="!hsCode">{{ item.hsCode.gtip2 }}</td>
                  <td class="desc">
                    <ng-container *ngIf="hsCode; else elseTemplate">
                        <b>{{ item.hsCode.gtip6 }}</b>
                        <div>{{ item.hsCode.gtip6desc }}</div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div>{{ item.hsCode.gtip2desc }}</div>
                    </ng-template>
                  </td>
                  <td class="imports">
                    <app-currency-formatter [value]="item.trade"></app-currency-formatter>
                  </td>
                  <td class="arrow">
                    <img src="../../assets/img/arrow-right.svg" />
                  </td>
                </tr>
              </tbody>
            </table>
      </div>

      <app-footer></app-footer>

    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="no-result">
      <div class="pb-5">Bu ülkenin herhangi bildirilmiş bir ithalatı bulunmamaktadır. Lütfen başka bir ülke seçiniz.</div>
      <button type="button" class="btn btn-primary" [routerLink]="['/country']" routerLinkActive="router-link-active" >BAŞKA BİR ÜLKE SEÇ</button>
    </div>
  </ng-template>
</section>
