import { ChartOptions, ChartType, ChartData, ChartDataset } from 'chart.js';

export class ChartConfig {
  options: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { x: {}, y: {} },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  constructor(
    public title: string = '',
    public subTitle: string = '',
    public dataSets: ChartDataset<'bar'>[] = [],
    public labels = [],
    public legend: boolean = false) {}
}
