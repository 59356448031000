<div class="search" #searchContainer>
    <div class="search-box" [ngClass]="{'focus': hasFocus}" #searchBox
      [style.width]="searcBoxWidth"
    >
        <span (click)="onPlaceholderClick()">{{ searchPlaceholder }}</span>
        <img src="../../../../assets/img/icon-search.svg" />
        <input type="text" #input [(ngModel)]="searchText"
          (blur)="onTextBoxBlur()"
          (keyup)="searchBoxKeyup($event)"
          [placeholder]="searchPlaceholder" />
        <a (click)="onCancelClick()" style="cursor: pointer;">Vazgeç</a>
      </div>

  <div class="search-result" #searchResult [style.height]="searchResultHeight">
    <ng-content></ng-content>
  </div>
</div>
