import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-currency-formatter',
  templateUrl: './currency-formatter.component.html',
  styleUrls: ['./currency-formatter.component.scss']
})
export class CurrencyFormatterComponent implements OnInit {
  @Input() value = 0;
  @Input() prefix: string;
  @Input() decimalPlaces = 2;
  @Input() locale = 'tr';
  @Input() suffix = '$';
  @Input() dividers = {
    billion: 1e8,
    million: 1e5,
    thousand: 100
  };

  constructor() { }

  ngOnInit() {
    if (this.value >= this.dividers.billion) {
      this.suffix = 'Milyar ' + this.suffix;
      this.value = this.value / (1e9);
    } else if (this.value >= this.dividers.million) {
      this.suffix = 'Milyon ' + this.suffix;
      this.value = this.value / (1e6);
    } else if (this.value >= this.dividers.thousand) {
      this.suffix = 'Bin ' + this.suffix;
      this.value = this.value / (1e3);
    }
  }
}
