import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

import { CountryImports, CountryImportItem } from '../models/country-imports.model';
import { ApiClientService } from '../services/api-client.service';

@Component({
  selector: 'app-gtip',
  templateUrl: './gtip.component.html',
  styleUrls: ['./gtip.component.scss']
})
export class GtipComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;

  country: string;
  hsCode: string;
  model: CountryImports;
  subscription: Subscription;
  isLoading = true;

  constructor(private apiClient: ApiClientService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.blockUI.start();

    this.country = this.route.snapshot.params.country;
    this.hsCode = this.route.snapshot.params.hsCode || '';

    this.subscription = this.apiClient
      .getImports(this.country, this.hsCode)
      .subscribe(data => {
        this.model = data;
        this.isLoading = false;
        this.blockUI.stop();
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onRowClick(item: CountryImportItem): void {
    let routeValue = '';

    this.apiClient.hsCode = item.hsCode.gtip6;

    if (this.hsCode) {
      routeValue = '/import-details/' + this.country + '/' + item.hsCode.gtip6;
    } else {
      routeValue = '/imports/' + this.country + '/' + item.hsCode.gtip2;
    }

    this.router.navigate([routeValue]);
  }
}
