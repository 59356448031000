<section class="home">
  <div class="tim-logo text-center">
    <img src="../../assets/img/tim-logo-tr.svg" alt="TİM" />
  </div>
  <h1 class="text-center">İhracat yapmaya hazır mısınız?</h1>
  <div class="row">
    <div class="col">
      <a [routerLink]="[ '/country']" class="home-btn">
        <img src="../../assets/img/icon-globe.svg" alt="HEDEF ÜLKEM" />
        <span>HEDEF ÜLKEM</span>
      </a>
    </div>
    <div class="col">
      <a [routerLink]="[ '/hs']" class="home-btn">
        <img src="../../assets/img/icon-box.svg" alt="BENİM ÜRÜNÜM" />
        <span>BENİM ÜRÜNÜM</span>
      </a>
    </div>
    <div class="col-12">
      <a [routerLink]="[ '/country']" [queryParams]="{c: 1}" class="home-btn d-flex align-items-center">
        <img src="../../assets/img/icon-agent.svg" class="mr-2" alt="BENİM TEMSİLCİM" />
        <span class="pt-0">BENİM TEMSİLCİM</span>
      </a>
    </div>
    <div class="col-12 mt-3">
      <a href="https://v2.ihracatradari.com" target="_blank" class="btn-ihracat-radari-v2">
        <img src="../../assets/img/icon-data.svg" alt="İhracat Radarı v2" />
        <span class="t">
          <span class="r s">YENİ NESİL</span>
          <span class="r">İHRACAT</span>
          <span class="r">RADARI 2.0 <span class="s">(Beta)</span></span>
        </span>

      </a>
    </div>
  </div>

</section>

<div class="bg-video">
  <video id="heroBgVideo" playsinline autoplay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
    <source src="https://tim.org.tr/files/downloads/home-01.mp4" type="video/mp4">
  </video>
</div>
