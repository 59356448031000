<header>
    <div>
      <table>
        <tr>
          <td class="back-arrow">
            <a (click)="onBackClick()" style="cursor:pointer;"><img src="../../assets/img/arrow-left.svg" /></a>
          </td>
          <td>
            <h1>{{ model.countryName }}</h1>
            <div class="hscode" *ngIf="hsCode || model.hsCode">
              <strong>{{ model.hsCode }}</strong>
              <div>{{ model.hsCodeName }}</div>
            </div>
            <div *ngIf="!hideTotalImports">
              <span class="year">{{ model.year }}</span> yılı toplam ithalatı
              <app-currency-formatter
                [value]="model.totalImportValue"
              ></app-currency-formatter>
            </div>
          </td>
        </tr>
      </table>
    </div>
</header>
