<section class="country-import-details country-imports" *ngIf="!isLoading">
  <app-header [hideTotalImports]="true" [model]="headerModel"></app-header>
  <div>
    <div class="bg-b-40 details-number-holder">
      <div class="row no-gutters">
        <div class="col">
          <div class="details-number">
            <h4>ÜLKENİN DÜNYADAN TOPLAM İTHALATI</h4>
            <app-currency-formatter [value]="importDetails.totalImport"></app-currency-formatter>
          </div>
        </div>
        <div class="col">
          <div class="details-number">
            <h4>ÜLKENİN DÜNYA İTHALATINDAKİ PAYI</h4>
            <span class="value">{{ importDetails.totalImportRatio | percent : '1.2-2' : 'tr-TR' }}</span>
          </div>
        </div>
        <div class="w-100 d-md-none"></div>
        <div class="col">
          <div class="details-number">
              <h4>TÜRKİYE'NİN ÜLKEYE TOPLAM İHRACATI</h4>
              <app-currency-formatter
                [value]="importDetails.totalImportFromTurkey"
                [dividers]="{ billion: 500 * 1000 * 1000, million: 100 * 1000, thousand: 100}">
              </app-currency-formatter>
          </div>
        </div>
        <div class="col">
          <div class="details-number">
            <h4>TÜRKİYE'NİN ÜLKE İTHALATINDAKİ PAYI</h4>
            <span class="value">{{ importDetails.totalImportFromTurkeyRatio | percent : '1.2-2' : 'tr-TR' }}</span>
          </div>
        </div>
      </div>



    </div>
  </div>
  <div>
    <div class="row no-gutters">
      <div class="col-md bg-b-60">
        <app-bar-chart [chartConfig]="chartCountryImportsFromWorld" ></app-bar-chart>
      </div>
      <div class="col-md bg-b-40 bg-b-60-md">
        <app-bar-chart [chartConfig]="chartCountryImportsFromTurkey" ></app-bar-chart>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md bg-b-60 bg-b-40-md">
        <app-bar-chart [chartConfig]="chartTopTenImporters" ></app-bar-chart>
      </div>
      <div class="col-md">
          <table class="table table-top-importers m-0">
              <thead>
                <tr>
                  <th>ÜLKE</th>
                  <th class="text-right">İTHALAT</th>
                  <th class="text-right">BİRİM FİYAT</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importer of topTenImporters" [ngClass]="{'turkey': (importer.country == 'Türkiye')}">
                  <td>{{ importer.country }}</td>
                  <td class="trade text-right">
                    <app-currency-formatter [value]="importer.trade"></app-currency-formatter>
                  </td>
                  <td class="unit-price text-right">
                    {{ importer.unitPrice | number : '1.1-2' : 'tr-TR' }} $/kg
                  </td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
  </div>
  <div class="text-center pt-4">
  <button
    type="button"
    class="btn btn-info"
    [routerLink]="['/contacts/' + importDetails.country]"
  >
    Ülke Temsilcilerimi Göster
  </button>
  </div>

  <app-footer></app-footer>
</section>


