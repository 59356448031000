import { Component } from '@angular/core';
import { Gtag } from 'angular-gtag';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'İhracat Pusulası';

  constructor(gtag: Gtag, router: Router) {
    router.events.pipe(
      distinctUntilChanged((previous: any, current: any) => {
        if (current instanceof NavigationEnd) {
            return previous.url === current.url;
        }
        return true;
    })).subscribe((x: any) => {
      gtag.pageview();
  });
  }
}
