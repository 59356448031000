<app-search
  searchPlaceholder="Ürün adı ya da GTIP kodu arayın"
  (searchKeyUp)="searchBoxKeyup($event)"
  >
  <ng-container *ngIf="isLoading; else elseNotIsLoadingTemplate">
      <div class="p-3 text-center" *ngIf="isLoading">Yükleniyor...</div>
  </ng-container>
  <ng-template #elseNotIsLoadingTemplate>
    <ng-container *ngIf="!hsCodes; else elseNotHsCodesTemplate">
        <div class="p-5 text-center">
            5300 farklı ürün grubu içinden daha detaylı bilgi almak istediğiniz ürünün adını ya da biliyorsanız GTIP kodunu yukarıdaki arama kutusuna girerek girerek arama yapabilirsiniz.
        </div>
    </ng-container>
    <ng-template #elseNotHsCodesTemplate>
      <ng-container *ngIf="hasNoItem; else elseHasNoItemTemplate">
          <div class="pt-4 px-1">
            Arama kriterlerine uygun bir sonuç bulunamadı.
          </div>
      </ng-container>
      <ng-template #elseHasNoItemTemplate>
          <div class="search-result-items">
            <div class="s-items-count">Arama sonucunda {{ hsCodes.length | number:'1.0-0' }} adet kayıt bulundu</div>
            <div class="s-item s-item-hscode" *ngFor="let item of hsCodes" (click)="onItemClick(item)">
              <div>
                <span>{{ item.gtip6 }}</span><span class="hs6-code">{{ item.gtip.substr(6) }}</span>
              </div>
              <div class="hs2-name">
                {{ item.gtip6desc }}
              </div>
              <div class="hs6-name">
                {{ item.gtipDesc }}
              </div>
            </div>
          </div>
      </ng-template>

    </ng-template>






  </ng-template>






</app-search>
