import { HsCode } from './hscode.model';

export class CountryImports {
  hsCode: string;
  hsCodeName: string;
  totalImportValue: number;
  year: number;
  countryName: string;
  countryCodes: string;
  items: CountryImportItem[];
}

export class CountryImportItem {
  hsCode: HsCode;
  trade: number;
}
