import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, zip } from 'rxjs';
import { ApiClientService } from '../services/api-client.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HsCode } from '../models/hscode.model';
import { HsImportItem, HsImports } from '../models/hs-imports.model';

@Component({
  selector: 'app-imports-by-countries',
  templateUrl: './imports-by-countries.component.html',
  styleUrls: ['./imports-by-countries.component.scss']
})
export class ImportsByCountriesComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;

  model: HsImports;
  subscription: Subscription;
  hsCode: HsCode;
  isLoading = true;

  constructor(private apiClient: ApiClientService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.blockUI.start();

    this.route.params.subscribe((params: Params) => {
      const hsCode = params.hsCode;

      this.subscription = zip(
        this.apiClient.getHsCodes(params.hsCode),
        this.apiClient.getTopImportersByHs(params.hsCode),
      )
      .subscribe(([hsCodes, topImporters]) => {
        this.hsCode = hsCodes[0];
        this.apiClient.hsCode = this.hsCode.gtip6;
        this.model = topImporters;

        this.blockUI.stop();
        this.isLoading = false;
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onRowClick(item: HsImportItem) {
    this.apiClient.country = item.country.name;
    this.router.navigate(['/import-details/' + item.country.name + '/' + this.hsCode.gtip6]);
  }
}
