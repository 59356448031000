import { Component, OnInit } from '@angular/core';
import { ApiClientService } from '../services/api-client.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Contact } from '../models/contact.model';
import { CountryImports } from '../models/country-imports.model';
import { Location } from '@angular/common';
import { NgBlockUI, BlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  contacts: Contact[] = [];
  headerModel = new CountryImports();
  isIOS = false;
  isLoading = true;
  mapsUrl = 'https://maps.google.com/maps?q=';

  constructor(
    private apiClient: ApiClientService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.blockUI.start();

    this.route.params.subscribe((params: Params) => {
      this.headerModel = new CountryImports();
      this.headerModel.hsCode = 'Ülke Temsilcileri';

      this.apiClient.getCountry(params.country)
        .subscribe(country => {
          this.headerModel.countryName = country.nameTr;
        });

      this.apiClient.getContacts(params.country)
      .subscribe(contacts => {
        this.contacts = contacts;
        this.isLoading = false;
        this.blockUI.stop();
      });
    });

    this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    this.mapsUrl = ((this.isIOS) ? 'http://maps.apple.com/?q=' : 'https://maps.google.com/maps?q=');
  }

  goBack() {
    this.location.back();
  }
}
