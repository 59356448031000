<app-search
  searchPlaceholder="Ülke seçin ya da arayın"
  (searchKeyUp)="searchBoxKeyup($event)"
  >
  <div class="p-3 text-center" *ngIf="isLoading">Yükleniyor...</div>
  <div class="search-result-items">
    <div class="s-item" *ngFor="let item of countries" (click)="onCountryClick(item)">
      {{ item.nameTr }}
    </div>
  </div>
</app-search>
