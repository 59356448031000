
<section class="contacts country-imports" *ngIf="!isLoading">
  <app-header [hideTotalImports]="true" [model]="headerModel" [onBackLinkClick]="goBack"></app-header>
  <div class="cards">
  <ng-container *ngIf="contacts && contacts.length > 0; else elseNoContactsTemplate">
      <div class="contact" *ngFor="let contact of contacts">
          <div class="card-title">
            <h2>{{ contact.name }}</h2>
            <div class="job-title">{{ contact.jobTitle }}</div>
            <div class="company">{{ contact.address.company }}</div>
          </div>
          <div class="contact-info-items">
            <div class="address contact-info d-flex" *ngIf="contact.address">
              <div class="flex-grow-1">
                <div class="label">ADRES</div>
                {{ contact.address.street }}, {{ contact.address.country }}
              </div>
              <a [href]="mapsUrl + contact.address.street + ',' + contact.address.country" target="_blank">
                <img src="../../assets/img/icon-map-pin.svg" />
              </a>
            </div>

            <ng-container *ngIf="contact.phones && contact.phones.length > 0">
              <div class="phone contact-info d-flex" *ngFor="let phone of contact.phones">
                <div class="flex-grow-1">
                  <div class="label">TELEFON</div>
                  <div>{{phone | formatPhone}}</div>
                </div>
                <a href="tel:{{phone}}"><img src="../../assets/img/icon-phone.svg" /></a>
              </div>
            </ng-container>

            <ng-container *ngIf="contact.faxes && contact.faxes.length > 0">
              <div class="fax contact-info d-flex" *ngFor="let fax of contact.faxes">
                <div class="flex-grow-1">
                  <div class="label">FAKS</div>
                  <div>{{fax | formatPhone}}</div>
                </div>
              </div>
            </ng-container>



            <ng-container *ngIf="contact.emails && contact.emails.length > 0">
              <div class="email contact-info d-flex" *ngFor="let email of contact.emails">
                <div class="flex-grow-1">
                  <div class="label">E-POSTA</div>
                  <div>{{email}}</div>
                </div>
                <a href="mailto:{{email}}"><img src="../../assets/img/icon-email.svg" /></a>
              </div>
            </ng-container>

            <div class="website contact-info d-flex" *ngIf="contact.address.webSite">
              <div class="flex-grow-1">
                <div class="label">WEB SİTESİ</div>
                <div>{{contact.address.webSite}}</div>
              </div>
              <a href="{{contact.address.webSite}}"><img src="../../assets/img/icon-link.svg" /></a>
            </div>
          </div>
      </div>
  </ng-container>
  <ng-template #elseNoContactsTemplate>
      <div class="no-result">
        <div class="pb-5">Bu ülkede herhangi bir temsilcimi bulunmamaktadır. Lütfen başka bir ülke seçiniz.</div>
        <button type="button" class="btn btn-primary" [routerLink]="['/country']" [queryParams]="{c: 1}" routerLinkActive="router-link-active" >BAŞKA BİR ÜLKE SEÇ</button>
      </div>
  </ng-template>
  </div>
</section>



