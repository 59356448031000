<div class="footer">
  <button
    type="button"
    class="btn btn-primary btn-lg"
    [routerLink]="['/hs']"
  >
    Başka bir ürün seçmek istiyorum
  </button>
  <div style="padding-top: 20px;">
    <a class="f-link-1" [routerLink]="['/country']">Başka bir ülke seçmek istiyorum</a>
  </div>
  <div style="padding-top: 20px;">
      <a class="f-link-1" [routerLink]="['/']">Yeniden başla</a>
    </div>
  <div style="padding-top: 30px;">
    <a class="f-link-2" href="https://comtrade.un.org" target="blank"
      >Kaynak: UN Comtrade</a
    >
  </div>
</div>
