import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

import { ApiClientService } from '../services/api-client.service';
import { CountryImports } from '../models/country-imports.model';
import { ImportDetails } from '../models/import-details.model';
import { Subscription, zip } from 'rxjs';

import { ChartConfig } from '../models/chart-config.model';
import { AppChartData } from '../models/chart-data.model';
import { TopImporters } from '../models/top-importers.model';
import { Title } from '@angular/platform-browser';
import { ChartData, ChartType } from 'chart.js';


@Component({
  selector: 'app-import-details',
  templateUrl: './import-details.component.html',
  styleUrls: ['./import-details.component.scss']
})
export class ImportDetailsComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;

  country: string;
  hsCode: string;
  subscription: Subscription;
  headerModel = new CountryImports();
  importDetails: ImportDetails;
  isLoading = true;

  chartCountryImportsFromWorld = new ChartConfig('ÜLKENİN DÜNYADAN İTHALATI', '(Milyar $)');
  chartCountryImportsFromTurkey = new ChartConfig('ÜLKENİN TÜRKİYE\'DEN İTHALATI', '(Milyar $)');
  chartTopTenImporters = new ChartConfig('EN ÇOK İTHALAT YAPTIĞI 10 ÜLKE VE TÜRKİYE', '(Milyar $)');
  topTenImporters: TopImporters[] = [];

  constructor(private apiClient: ApiClientService, private route: ActivatedRoute, private router: Router, private titleService: Title) { }

  calculateAverage = (arr: number[]) => arr.reduce( ( p: number, c: number ) => p + c, 0 ) / arr.length;

  formaChartValues(arr: number[]): { items: number[], suffix: string } {
    const average = this.calculateAverage(arr);
    let divider = 1;
    let suffix = '$';

    let items = [...arr];

    if (average >= 2e8) {
      divider = 1e9;
      suffix = 'Milyar $';
    } else if (average >= 4e5) {
      divider = 1e6;
      suffix = 'Milyon $';
    } else if (average >= 200) {
      divider = 1e3;
      suffix = 'Bin $';
    }

    if (divider > 1) {
      items = items.map(item => {
        return parseFloat((item / divider).toFixed(2));
      });
    }

    return {
      items,
      suffix
    };
  }

  truncate(text: string, n: number) {
    return (text.length > n) ? text.substr(0, n - 1) + '...' : text;
  }

  getBarChartConfig(title: string, data: AppChartData[], topImporterData: TopImporters[] = null): ChartConfig {
    let dataItems = [];
    const dataLabels = [];
    const defaultBarBgColor = 'rgba(76,162,255,0.7)';
    const defaultBarHoverBgColor = 'rgba(76,162,255,1)';
    let backgroundColor: string | string[] = defaultBarBgColor;
    let hoverBackgroundColor: string | string[] = defaultBarHoverBgColor;

    if (topImporterData == null) {
      data.forEach(item => {
          dataItems.push(item.value);
          dataLabels.push(item.key);
      });
    } else {
      const bgColors = [];
      const hBgColors = [];

      topImporterData.forEach(item => {
        dataItems.push(item.trade);
        dataLabels.push(this.truncate(item.country, 9));

        if (item.country === 'Türkiye') {
          bgColors.push('rgb(256,0,0,0.7)');
          hBgColors.push('rgb(256,0,0,1)');
        } else {
          bgColors.push(defaultBarBgColor);
          hBgColors.push(defaultBarHoverBgColor);
        }
      });

      backgroundColor = bgColors;
      hoverBackgroundColor = hBgColors;
    }


    const formatedValues = this.formaChartValues(dataItems);

    dataItems = [...this.formaChartValues(dataItems).items];

    console.log(dataItems, dataLabels);

    return new ChartConfig(
      title,
      '(' + formatedValues.suffix + ')',
      [
        {
          barThickness: 15,
          maxBarThickness: 15,
          backgroundColor,
          hoverBackgroundColor,
          data: dataItems
        }
      ],
      dataLabels,
      false);
  }

  ngOnInit(): void {
    this.titleService.setTitle( 'İhracat Detayları' );

    this.blockUI.start();

    this.route.params.subscribe((params: Params) => {
      this.country = this.apiClient.country = params.country;
      this.hsCode =  this.apiClient.hsCode = params.hsCode;

      this.subscription = zip(this.apiClient.getImportDetails(this.country, this.hsCode),
        this.apiClient.getChartData(this.hsCode, this.country),
        this.apiClient.getChartData(this.hsCode, this.country, 1),
        this.apiClient.getTopImporters(this.country, this.hsCode),
      )
      .subscribe(([importDetails, importsFromWorld, importsFromTurkey, topImporters]) => {
        this.importDetails = importDetails;

        const model = new CountryImports();

        model.countryName = importDetails.countryName;
        model.hsCode = importDetails.hsCode;
        model.hsCodeName = importDetails.hsCodeName;
        model.totalImportValue = importDetails.totalImport;
        model.items = [];
        model.year = 0;

        this.headerModel = model;

        this.chartCountryImportsFromWorld = this.getBarChartConfig(this.chartCountryImportsFromWorld.title, importsFromWorld);
        this.chartCountryImportsFromTurkey = this.getBarChartConfig(this.chartCountryImportsFromTurkey.title, importsFromTurkey);

        this.topTenImporters = topImporters;
        this.chartTopTenImporters = this.getBarChartConfig(this.chartTopTenImporters.title, [], topImporters);

        this.isLoading = false;
        this.blockUI.stop();
      });
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }

      window.scrollTo(0, 0);
  });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
