import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiClientService } from '../services/api-client.service';
import { Country } from '../models/country.model';
import { Utils } from '../shared/utils';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  countries: Country[];
  selectedCountry: Country;

  constructor(private apiClient: ApiClientService, private router: Router) { }

  ngOnInit() {
    this.apiClient.country = null;
    this.apiClient.hsCode = null;

    // this.apiClient.getCountries().subscribe(countries => {
    //   this.countries = countries;
    // });
  }

  // onCountrySelected() {
  //   if (this.selectedCountry) {
  //     this.router.navigate(['imports', this.selectedCountry.name]);
  //   }
  // }
}
