import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {

  hasFocus = false;
  cancelRoute = '/';
  searchText = '';
  searchResultHeight = 'auto';
  searcBoxWidth = 'auto';

  @ViewChild('searchContainer', { static: true }) searchContainerArea: ElementRef<HTMLDivElement>;
  @ViewChild('searchBox', { static: true }) searchBoxArea: ElementRef<HTMLDivElement>;
  @ViewChild('searchResult', { static: true }) searchResultArea: ElementRef<HTMLDivElement>;
  @ViewChild('input', { static: true }) textBox: ElementRef;
  @Input() searchPlaceholder = '';
  @Output() searchKeyUp: EventEmitter<any> = new EventEmitter();

  constructor(private location: Location) { }

  ngOnInit() {
    this.serSearchResultAreaHeight();
  }

  private serSearchResultAreaHeight() {
    this.searchResultHeight = window.innerHeight - this.searchBoxArea.nativeElement.clientHeight + 'px';
  }

  ngAfterViewInit(): void {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.serSearchResultAreaHeight();
  }

  onPlaceholderClick(): void {
    this.hasFocus = true;
    this.textBox.nativeElement.focus();
  }

  onTextBoxBlur(): void {
    if (this.searchText !== '') {
      this.hasFocus = true;
    } else {
      this.hasFocus = false;
    }
  }

  searchBoxKeyup(event: any) {
    this.searchKeyUp.emit(event);
  }

  onCancelClick() {
    this.location.back();
  }
}
