import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Country } from '../models/country.model';
import { ApiClientService } from '../services/api-client.service';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-country-search',
  templateUrl: './country-search.component.html',
  styleUrls: ['./country-search.component.scss']
})
export class CountrySearchComponent implements OnInit, OnDestroy {
  isLoading = true;
  countries: Country[];
  countryList: Country[];
  subscription: Subscription;
  subject: Subject<any> = new Subject();
  isSearchTargetContacts = false;

  constructor(private apiClient: ApiClientService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.apiClient.getCountries().subscribe(countries => {
      this.countries = [...countries];
      this.countryList = [...countries];
      this.isLoading = false;
    });

    this.subscription = this.subject
      .pipe(debounceTime(300))
      .subscribe((value: string) => {
          if (value) {
            this.countries = [...this.countryList.filter(c =>
              c.nameTr.startsWith(value) ||
              c.nameTr.startsWith(value.toLowerCase()) ||
              c.nameTr.startsWith(value.toLocaleLowerCase()) ||
              c.nameTr.startsWith(value.toLocaleUpperCase()) ||
              c.nameTr.toLocaleLowerCase().startsWith(value.toLocaleLowerCase()) ||
              c.nameTr.toLocaleUpperCase().startsWith(value.toLocaleUpperCase()) ||
              c.nameTr.toUpperCase().startsWith(value.toUpperCase()) ||
              c.nameTr.toLowerCase().startsWith(value.toLowerCase()) ||
              c.name.startsWith(value.toLowerCase())
              )];
          } else {
            this.countries = [...this.countryList];
          }
        }
      );

    this.route.queryParams.subscribe(params => {
        this.isSearchTargetContacts = params.c === '1';
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchBoxKeyup(event: any) {
    this.subject.next(event.target.value);
  }

  onCountryClick(country: Country): void {
    if (this.isSearchTargetContacts) {
      this.router.navigate(['/contacts/' + country.name]);
      return;
    }

    this.apiClient.country = country.name;

    const path = (this.apiClient.hsCode)
        ? '/import-details/' + this.apiClient.country + '/' + this.apiClient.hsCode
        : '/imports/' + this.apiClient.country;

    this.router.navigate([path]);
  }
}
