import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartData, Chart, ChartDataset } from 'chart.js';
import { ChartConfig } from '../models/chart-config.model';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @Input() chartConfig: ChartConfig;


  title: string;
  subTitle: string;
  plugins = [ChartDataLabels];
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar:{
        backgroundColor: 'rgba(76,162,255,0.7)',
        hoverBackgroundColor: 'rgba(76,162,255,1)',

        //backgroundColor:
      }
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        grid: {
          display: false,
          lineWidth: 0,
          color: 'rgba(255,255,255,0)'
        },
        ticks: {
          color: 'rgba(255,255,255,0.7)',
          font:{
            family: 'pn',
            size: 10
          },
        }
      },
      y: {
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: 'rgba(255,255,255,.2)'
        },
        ticks: {
          color: 'rgba(255,255,255,0.7)',
          font:{
            family: 'pn',
            size: 10,
          },
          padding: 5
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: false
      },
      datalabels: {
        color: '#FFF',
        display: true,
        anchor: 'end',
        align: 'end',
        font: {
          family: 'pn',
          size: 10,
          weight: 300,
          lineHeight: 1
        },
        padding: {
          top: 0,
          bottom: 5,
          left: 0,
          right: 0
        },
        formatter(value, context) {
          return (value * 1).toFixed(2).replace('.', ',');
        }
      }
    }
  };

  labels = [];
  legend = false;
  // chartPlugins = [pluginDataLabels];
  dataSets: ChartDataset<'bar', number[]>[];

  constructor() { }

  ngOnInit() {
    if (this.chartConfig) {
      this.title = this.chartConfig.title || this.title;
      this.subTitle = this.chartConfig.subTitle || this.subTitle;
      this.labels = this.chartConfig.labels || this.labels;
      // this.options = this.chartConfig.options || this.options;
      this.legend = this.chartConfig.legend || this.legend;
      this.dataSets = this.chartConfig.dataSets || this.dataSets;
    }
  }

}
