import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { BlockUIModule } from 'ng-block-ui';
import { GtagModule } from 'angular-gtag';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { GtipComponent } from './gtip/gtip.component';
import { CurrencyFormatterComponent } from './shared/components/currency-formatter/currency-formatter.component';
import { ImportDetailsComponent } from './import-details/import-details.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SlugPipe } from './shared/pipes/slug.pipe';
import { SearchComponent } from './shared/components/search/search.component';
import { CountrySearchComponent } from './country-search/country-search.component';
import { HsSearchComponent } from './hs-search/hs-search.component';
import { ImportsByCountriesComponent } from './imports-by-countries/imports-by-countries.component';
import { environment } from 'src/environments/environment';
import { ContactsComponent } from './contacts/contacts.component';
import { FormatPhonePipe } from './shared/pipes/format-phone.pipe';

registerLocaleData(localeTr, 'tr-TR', localeTrExtra);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GtipComponent,
    CurrencyFormatterComponent,
    ImportDetailsComponent,
    FooterComponent,
    HeaderComponent,
    BarChartComponent,
    ErrorPageComponent,
    SlugPipe,
    SearchComponent,
    CountrySearchComponent,
    HsSearchComponent,
    ImportsByCountriesComponent,
    ContactsComponent,
    FormatPhonePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgChartsModule,
    BlockUIModule.forRoot(),
    GtagModule.forRoot({ trackingId: environment.googleAnalytics.trackingId, trackPageviews: true })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
