import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Country } from '../models/country.model';
import { CountryImports } from '../models/country-imports.model';
import { ImportDetails } from '../models/import-details.model';
import { AppChartData } from '../models/chart-data.model';
import { TopImporters } from '../models/top-importers.model';
import { throwError } from 'rxjs';
import { HsCode } from '../models/hscode.model';
import { retry, catchError } from 'rxjs/operators';
import { HsImports } from '../models/hs-imports.model';
import { Contact } from '../models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {
  hsCode: string;
  country: string;

  readonly baseUrl = 'https://api.ihracatradari.com/comtrade/';
  // readonly baseUrl = 'https://uncomtradeapi.tim.rest/comtrade/';
  // readonly baseUrl = 'https://localhost:44354/comtrade/';
  readonly urls = {
    getHsCodes: this.baseUrl + 'gethscodes',
    getCountries: this.baseUrl + 'getcountries',
    getCountryImports: this.baseUrl + 'getcountryimports',
    getImportDetails: this.baseUrl + 'getimportdetails',
    getChartData: this.baseUrl + 'getchartdata',
    getTopImporters: this.baseUrl + 'gettopimporters',
    getTopImportersByHs: this.baseUrl + 'gettopimportersbyhs',
    getContacts: this.baseUrl + 'getcontacts',
    getCountry: this.baseUrl + 'getcountry',
  };

  constructor(private http: HttpClient) { }

  private get<T>(url: string,
                 options?: {
                   headers?: HttpHeaders | { [header: string]: string | string[]; };
                   observe?: 'body';
                   params?: HttpParams | { [param: string]: string | string[]; };
                   reportProgress?: boolean;
                   responseType?: 'json';
                   withCredentials?: boolean; } ) {
    return this.http.get<T>(url, options)
                  .pipe(
                      retry(3),
                      catchError(this.handleError)
                  );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Veri alınırken bir hata oluştu! Lütfen daha sonra tekrar deneyiniz.');
  }

  getCountries() {
    return this.get<Country[]>(this.urls.getCountries);
  }

  getHsCodes(text: string) {
    const params = new HttpParams().set('q', text);
    return this.get<HsCode[]>(this.urls.getHsCodes, { params });
  }

  getChartData(hsCode: string, country: string, chartType: number = 0) {
    const params = new HttpParams().set('hs', hsCode).set('c', country).set('t', chartType + '');
    return this.get<AppChartData[]>(this.urls.getChartData, { params });
  }

  getImports(country: string, hsCode: string) {
    const params = new HttpParams().set('hs', hsCode).set('c', country);
    return this.get<CountryImports>(this.urls.getCountryImports, { params });
  }

  getImportDetails(country: string, hsCode: string) {
    const params = new HttpParams().set('hs', hsCode).set('c', country);
    return this.get<ImportDetails>(this.urls.getImportDetails, { params });
  }

  getTopImporters(country: string, hsCode: string) {
    const params = new HttpParams().set('hs', hsCode).set('c', country);
    return this.get<TopImporters[]>(this.urls.getTopImporters, { params });
  }
  getTopImportersByHs(hsCode: string) {
    const params = new HttpParams().set('hs', hsCode);
    return this.get<HsImports>(this.urls.getTopImportersByHs, { params });
  }

  getContacts(country: string) {
    const params = new HttpParams().set('c', country);
    return this.get<Contact[]>(this.urls.getContacts, { params });
  }

  getCountry(country: string) {
    const params = new HttpParams().set('countryName', country);
    return this.get<Country>(this.urls.getCountry, { params });
  }
}


