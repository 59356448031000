import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CountryImports } from '../models/country-imports.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() model: CountryImports;
  @Input() hideTotalImports: boolean;
  @Input() onBackLinkClick: () => void;

  country: string;
  hsCode: string;

  constructor(private route: ActivatedRoute, private router: Router, private location: Location) { }

  ngOnInit() {
    this.country = this.route.snapshot.params.country;
    this.hsCode = this.route.snapshot.params.hsCode || '';
  }

  getBackLink(): string[] {
    if (this.hsCode && this.country) {
      if (this.hsCode.length > 2) {
        return ['/imports/' + this.country + '/' + this.hsCode.substr(0, 2)];
      } else {
        return ['/imports/' + this.country];
      }
    } else {
      return ['/'];
    }
  }

  onBackClick() {
    if (this.onBackLinkClick) {
      this.onBackLinkClick();
      return;
    }

    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }
}
