<div class="chart-area">
  <div class="chart-header">
      <h3>{{ title }}</h3>
      <div>{{ subTitle }}</div>
  </div>

  <div class="chart-canvas" style="min-height: 235px;">
    <canvas
      baseChart
      [datasets]="dataSets"
      [labels]="labels"
      [options]="options"
      [legend]="legend"
      [plugins]="plugins"
      chartType="bar"
    >
    </canvas>
  </div>

</div>
