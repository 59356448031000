import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiClientService } from '../services/api-client.service';
import { HsCode } from '../models/hscode.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  hsCode: string;
  country: string;
  hsCodes: HsCode[];
  subject: Subject<any> = new Subject();
  subscription: Subscription;
  isLoading = false;
  hasNoItem = false;
  searchboxHeight: number;

  constructor(private apiClient: ApiClientService,
              private route: ActivatedRoute,
              private router: Router) {  }

  ngOnInit(): void {
    this.country = this.route.snapshot.params.country;
    this.hsCode = this.route.snapshot.params.hsCode || '';
    this.searchboxHeight = window.innerHeight - 310;

    this.subscription = this.subject
      .pipe(debounceTime(300))
      .subscribe((value: string) => {
          if (value) {
            this.isLoading = true;

            this.apiClient.getHsCodes(value)
                .subscribe((data) => {
                  this.isLoading = false;
                  this.hsCodes = data;
                  this.hasNoItem = (data.length === 0);
                });
          } else {
            this.hsCodes = [];
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchBoxKeyup(event: any) {
    this.subject.next(event.target.value);
  }

  onHsCodeSelect(hsCode: string) {
    if (hsCode) {
      this.router.navigate(['/import-details/' + this.country + '/' + hsCode]);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.searchboxHeight = window.innerHeight - 310;
  }
}
