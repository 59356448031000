import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiClientService } from '../services/api-client.service';
import { Subject, Subscription } from 'rxjs';
import { Country } from '../models/country.model';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { HsCode } from '../models/hscode.model';

@Component({
  selector: 'app-hs-search',
  templateUrl: './hs-search.component.html',
  styleUrls: ['./hs-search.component.scss']
})
export class HsSearchComponent implements OnInit, OnDestroy {
  hsCodes: HsCode[];
  subscription: Subscription;
  subject: Subject<any> = new Subject();
  hasNoItem = false;
  isLoading = false;

  constructor(private apiClient: ApiClientService, private router: Router) { }

  ngOnInit() {
    this.subscription = this.subject
      .pipe(debounceTime(300))
      .subscribe((value: string) => {
          if (value) {
            this.isLoading = true;

            this.apiClient.getHsCodes(value)
                .subscribe((data) => {
                  this.isLoading = false;
                  this.hsCodes = data;
                  this.hasNoItem = (data.length === 0);
                });
          } else {
            this.hsCodes = null;
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchBoxKeyup(event: any) {
    this.subject.next(event.target.value);
  }

  onItemClick(item: HsCode): void {
    this.apiClient.hsCode = item.gtip6;

    const path = (this.apiClient.country)
      ? '/import-details/' + this.apiClient.country + '/' + this.apiClient.hsCode
      : '/imports-by-countries/' + this.apiClient.hsCode;

    this.router.navigate([path]);
  }
}
